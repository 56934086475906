import React from 'react';
import GifVideo from '../../shared/GifVideo';
import premiumVideo from '../../../assets/video/premium.mp4';
import '../../../images/premium/premium-icon-1.png';
import { StaticImage } from 'gatsby-plugin-image';
import Logo from '../../../assets/logo.svg';


export default function Premium() {
    return (
        <div style={{ marginBottom: '190px' }}>
            <h3 className='text-3xl md:text-4xl font-bold mb-8 text-center'>
                <span style={{ display: 'block' }}><span className='text-primary'>Premium</span> helps you achieve an organized workspace for improved</span>
                <span style={{ display: 'block' }}>focus and mental clarity</span>
            </h3>
            {/*<div style={{ maxWidth: '1200px', marginBottom: '150px' }}>*/}
            {/*    <GifVideo video={premiumVideo} />*/}
            {/*</div>*/}
            {/*<h3 style={{ marginBottom: '150px' }} className='text-3xl md:text-4xl font-bold text-center'>*/}
            {/*    ...without breaking the bank.*/}
            {/*</h3>*/}
            <div style={{ margin: '125px 0' }} className='flex flex-column lg:flex-row justify-content-center align-items-center font-helvetica text-xl'>
                <div style={{
                    border: '5px dashed #B7B7B7',
                    borderRadius: '10px',
                    padding: '16px 20px',
                    width: '260px'
                }}>
                    <p className='font-medium mb-3'>Music & Sounds</p>
                    <div className='mb-4'>
                        <StaticImage className='mr-2' src='../../../images/premium/premium-icon-1.png' alt='' />
                        <StaticImage src='../../../images/premium/premium-icon-2.png' alt='' />
                        <StaticImage src='../../../images/premium/premium-icon-3.png' alt='' />
                    </div>
                    <p className='font-bold'>$6/month</p>
                </div>
                <p className='m-4' style={{ color: '#9E9E9E' }}>+</p>
                <div>
                    <div style={{
                        border: '5px dashed #B7B7B7',
                        borderRadius: '10px',
                        padding: '16px 20px'
                    }}>
                        <p className='font-medium mb-3'>Time tracking & To-dos</p>
                        <div className='mb-4'>
                            <StaticImage className='mr-3' src='../../../images/premium/premium-icon-4.png' alt='' />
                            <StaticImage className='relative' style={{ top: '5px' }} src='../../../images/premium/premium-icon-5.png' alt='' />
                        </div>
                        <p className='font-bold'>$6/month</p>
                    </div>
                </div>
                <p className='m-4' style={{ color: '#9E9E9E' }}>=</p>
                <p className='font-bold m-4'>$14/month</p>
                <div>
                    <div style={{
                        border: '5px dashed #F3A952',
                        borderRadius: '10px',
                        padding: '16px 100px 16px 0'
                    }}>
                        <Logo className='h-4rem mb-6' />
                        <p className='font-bold pl-4'>$3.99/month</p>
                    </div>
                </div>
            </div>
            <h3 className='text-3xl md:text-4xl font-bold text-center'>
                ...without breaking the bank.
            </h3>
        </div>
    );
}