import React from 'react';

// styles
import '../styles/index.css';
import '/node_modules/primeflex/primeflex.css';

// components
import MainLayout from '../hoc/MainLayout';
import Hero from '../components/pricing/Hero';
import Pricing from '../components/pricing/Pricing';
import Faq from '../components/pricing/Faq';
import Mosaic from '../components/pricing/Mosaic';
import { loadCrispChat } from '../common/utils';
import Seo from '../components/shared/Seo';
import UsedBy from '../components/pricing/UsedBy';
import Premium from '../components/pricing/Premium';

const PricingPage = () => {
    loadCrispChat();

    return (
        <MainLayout>
            <Seo title='Lofi.co - Craft your focus environment' keywords={['music', 'focus', 'relax', 'work']} lang='en' />
            <main>
                <div className='block md:hidden'>
                    <div className='flex flex-column align-items-center'>
                        <Mosaic />
                        <p className='mb-8 text-center font-bold font-helvetica text-lg mb-6'>
                            <span style={{ display: 'block' }}>1% of every sale supports mental health</span>
                            <span style={{ display: 'block' }}>organizations worldwide</span>
                        </p>
                        <Hero />
                        <Pricing />
                        <UsedBy />
                        <Premium />
                        <Faq />
                    </div>
                </div>
                <div className='hidden md:block'>
                    <div className='flex flex-column align-items-center'>
                        <Hero />
                        <p className='mb-4 text-center font-bold font-bold font-helvetica text-xl mb-6'>
                            <span style={{ display: 'block' }}>1% of every sale supports mental health</span>
                            <span style={{ display: 'block' }}>organizations worldwide</span>
                        </p>
                        <Pricing />
                        <UsedBy />
                        <Premium />
                        <Faq />
                        <Mosaic />
                    </div>
                </div>
            </main>
        </MainLayout>
    );
};

export default PricingPage;