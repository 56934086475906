import React from 'react';
import FeedbackCard from '../../shared/FeedbackCard';
import { feedbacks } from '../../../common/feedbacks';

export default function MosaicItems() {
    return (
        <>
            <div className='flex flex-column mt-7'>
                <FeedbackCard theme='light' feedback={feedbacks[0]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[1]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[2]} type='mosaic' />
            </div>
            <div className='flex flex-column mb-7'>
                <FeedbackCard theme='light' feedback={feedbacks[3]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[4]} type='mosaic' />
            </div>
            <div className='flex flex-column relative mt-7'>
                <FeedbackCard theme='light' feedback={feedbacks[5]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[6]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[7]} type='mosaic' />
            </div>
            <div className='flex flex-column mb-7'>
                <FeedbackCard theme='light' feedback={feedbacks[8]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[9]} type='mosaic' />
            </div>
            <div className='flex flex-column relative mt-7'>
                <FeedbackCard theme='light' feedback={feedbacks[10]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[11]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[12]} type='mosaic' />
            </div>
            <div className='flex flex-column mb-7'>
                <FeedbackCard theme='light' feedback={feedbacks[13]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[14]} type='mosaic' />
            </div>
            <div className='flex flex-column relative mt-7'>
                <FeedbackCard theme='light' feedback={feedbacks[15]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[16]} type='mosaic' />
            </div>
            <div className='flex flex-column mb-7'>
                <FeedbackCard theme='light' feedback={feedbacks[17]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[18]} type='mosaic' />
            </div>
            <div className='flex flex-column relative mt-7'>
                <FeedbackCard theme='light' feedback={feedbacks[19]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[20]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[21]} type='mosaic' />
            </div>
            <div className='flex flex-column mb-7'>
                <FeedbackCard theme='light' feedback={feedbacks[22]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[23]} type='mosaic' />
            </div>
            <div className='flex flex-column relative mt-7'>
                <FeedbackCard theme='light' feedback={feedbacks[24]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[25]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[26]} type='mosaic' />
            </div>
            <div className='flex flex-column mb-7'>
                <FeedbackCard theme='light' feedback={feedbacks[27]} type='mosaic' />
                <FeedbackCard theme='light' feedback={feedbacks[28]} type='mosaic' />
            </div>
        </>
    );
}