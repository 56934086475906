import styled, { keyframes } from 'styled-components';

const scroll = keyframes`
  from {
    transform: translateX(0%)
  }
  
  to {
    transform: translateX(-50%)
  }
`;

export const Animation = styled.div`
  overflow-x: hidden;
  position: relative;
  height: 40rem;
`;

export const MosaicContainer = styled.div`
  animation: ${scroll} 200s linear infinite;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
`;