import { appUrl } from './constants';

export const prices = [
    {
        id: 0,
        plan: 'Basic',
        redirect: `${appUrl}?pricing=true`,
        monthlyPrice: '$0',
        yearlyPrice: null,
        included: [
            { feature: 'Ad-free' },
            { feature: 'Unlimited music streaming' },
            { feature: '2 scenes' },
            { feature: '3 soundscapes' }
        ],
        excluded: [
            { feature: 'Priority support' },
            { feature: 'Save your favorite templates' },
            { feature: 'Download wallpapers' },
            { feature: 'To-do\'s' },
            { feature: 'Advanced timer' },
            { feature: 'Notepad' },
            { feature: 'Insights' },
            { feature: 'Support mental health organizations*' }
        ],
        disclaimer: null
    },
    {
        id: 1,
        plan: 'Premium',
        redirect: `${appUrl}?pricing=true`,
        monthlyPrice: '$3,99',
        yearlyPrice: '$2,99',
        included: [
            { feature: 'Ad-free' },
            { feature: 'Unlimited music streaming' },
            { feature: '20+ scenes and growing' },
            { feature: '15+ sounds to mix and match to your liking' },
            { feature: 'Priority support' },
            { feature: 'Save your favorite templates' },
            { feature: 'Download wallpapers' },
            { feature: 'To-do’s' },
            { feature: 'Advanced timer' },
            { feature: 'Notepad' },
            { feature: 'Insights' },
            { feature: 'Support mental health organizations' }
        ],
        excluded: null,
        disclaimer: '*7-day money back guarantee.\n' +
            'Cancel at any time.'
    },
    {
        id: 2,
        plan: 'Lifetime',
        redirect: `${appUrl}?pricing=true`,
        monthlyPrice: '$99,99',
        yearlyPrice: null,
        included: [
            { feature: 'Ad-free' },
            { feature: 'Unlimited music streaming' },
            { feature: '20+ scenes and growing' },
            { feature: '15+ sounds to mix and match to your liking' },
            { feature: 'Priority support' },
            { feature: 'Save your favorite templates' },
            { feature: 'Download wallpapers' },
            { feature: 'To-do’s' },
            { feature: 'Advanced timer' },
            { feature: 'Notepad' },
            { feature: 'Insights' },
            { feature: 'Support mental health organizations' }
        ],
        excluded: null,
        disclaimer: '*7-day money back guarantee.\n' +
            'Cancel at any time.'
    },
];