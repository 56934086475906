import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import WordsScroller from '../../shared/WordsScroller';
import '../../../images/logos/apple-logo.png';

export default function UsedBy() {
    return (
        <div style={{ marginBottom: '180px' }}>
            <h3 className='text-3xl md:text-4xl font-bold mb-8 text-center'>
                Used by <WordsScroller /> at
            </h3>
            <div className='hidden md:block'>
                <StaticImage className='mx-4' src='../../../images/logos/apple-logo.png' alt='' />
                <StaticImage className='mx-4 mt-3' src='../../../images/logos/twitter-logo.png' alt='' />
                <StaticImage className='mx-4' src='../../../images/logos/yale-logo.png' alt='' />
                <StaticImage className='mx-4' src='../../../images/logos/um-logo.png' alt='' />
                <StaticImage className='mx-4' src='../../../images/logos/queens-logo.png' alt='' />
            </div>
            <div className='block md:hidden'>
                <div className='mb-6'>
                    <StaticImage width='60' className='mx-4' src='../../../images/logos/apple-logo.png' alt='' />
                    <StaticImage width='60' className='mx-4 mt-3' src='../../../images/logos/twitter-logo.png' alt='' />
                    <StaticImage className='mx-4' src='../../../images/logos/yale-logo.png' alt='' />
                </div>
                <div className='flex justify-content-center'>
                    <StaticImage width='60' className='mx-4' src='../../../images/logos/um-logo.png' alt='' />
                    <StaticImage width='100' className='mx-4' src='../../../images/logos/queens-logo.png' alt='' />
                </div>
            </div>
        </div>
    );
}