import React, { useEffect, useRef, useState } from 'react';

export default function WordsScroller() {
    const words = ['engineers', 'students', 'developers', 'designers'];
    const [index, setIndex] = useState(0);
    const ref = useRef();
    const [width, setWidth] = useState(ref.current?.offsetWidth);

    useEffect(() => {
        const lastIndex = words.length - 1;
        if (index < 0) {
            setIndex(lastIndex);
        }
        if (index > lastIndex) {
            setIndex(0);
        }
    }, [index, words]);

    useEffect(() => {
        let slider = setInterval(() => {
            setIndex(index + 1);
        }, 3000);
        return () => {
            clearInterval(slider);
        };
    }, [index]);

    useEffect(() => {
        setWidth(ref.current?.offsetWidth);
    }, [index]);

    const slide = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        transition: 'all 0.3s linear'
    };

    const activeSlide = {
        opacity: 1,
        transform: 'translateY(0)'
    };

    const lastSlide = {
        transform: 'translateY(0)'
    };

    const nextSlide = {
        transform: 'translateY(100%)'
    };

    return (
        <>
            <div ref={ref} className='inline-block opacity-0'>{words[index]}</div>
            <div style={{ right: width }} className='inline relative text-primary'>
                {words.map((word, wordIndex) => {
                    let position = nextSlide;
                    if (wordIndex === index) {
                        position = activeSlide;
                    }
                    if (
                        wordIndex === index - 1 ||
                (index === 0 && wordIndex === words.length - 1)
                    ) {
                        position = lastSlide;
                    }
                    return <span key={wordIndex} style={{ ...slide, ...position }}>{word}</span>;
                })}
            </div>
        </>
    );
}