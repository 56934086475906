import React, { useState } from 'react';
import { prices } from '../../../common/pricing';
import PriceCard from '../PriceCard';
import Selection from '../../shared/Selection';
import ArrowIcon from '../../../assets/arrow.svg';

export default function Pricing() {
    const options = ['Pay Monthly', 'Pay Yearly'];
    const [selected, setSelected] = useState(options[1]);

    return (
        <section className='flex flex-column align-items-center mb-8'>
            <div className='flex md:ml-6 md:-mr-8'>
                <Selection options={options} selected={selected} setSelected={setSelected} />
                <div className='ml-6 hidden md:block'>
                    <ArrowIcon style={{ position: 'relative', right: '50%' }} />
                    <p className='font-bold text-primary font-helvetica' style={{ transform: 'rotate(10deg)' }}>Save 25%</p>
                </div>
            </div>
            <div className='flex flex-column lg:flex-row'>
                {prices.map(price => {
                    return (
                        <PriceCard key={price.plan} price={price} subscription={selected} />
                    );
                })}
            </div>
        </section>
    );
}