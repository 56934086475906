import React from 'react';
import { SecondaryBorderLinkButton } from '../../shared/StyledButtons/styled';
import SuccessIcon from '../../../assets/success-icon.svg';
import ErrorIcon from '../../../assets/error-icon.svg';

export default function PriceCard({ price, subscription }) {
    const { id, plan, monthlyPrice, yearlyPrice, included, excluded, disclaimer, redirect } = price;

    const getPrice = () => {
        if (subscription === 'Pay Monthly') {
            if (id === 0 || id === 2) {
                return <p className='font-bold text-3xl mb-3'>{monthlyPrice}</p>;
            } else {
                return <p className='font-bold text-3xl mb-3'>{monthlyPrice}<sub className='text-sm'>/mo</sub></p>;
            }
        } else if (subscription === 'Pay Yearly') {
            if (id === 0 || id === 2) {
                return <p className='font-bold text-3xl mb-3'>{monthlyPrice}</p>;
            } else {
                return <p className='font-bold text-3xl mb-3'>{yearlyPrice}<sub className='text-sm'>/mo</sub></p>;
            }
        }
    };

    const getRedirect = () => {
        if (subscription === 'Pay Monthly') {
            if (id === 0 || id === 2) {
                return redirect;
            } else {
                return redirect + 'month';
            }
        } else if (subscription === 'Pay Yearly') {
            if (id === 0 || id === 2) {
                return redirect;
            } else {
                return redirect + 'year';
            }
        }
    };

    return (
        <div style={{
            boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.1)',
            height: 'fit-content'
        }} className='bg-white border-round-2xl py-6 px-5 mx-2 mb-4 w-20rem font-roboto'>
            <p className='font-bold mb-3 font-helvetica text-xl'>{plan}</p>
            {getPrice()}
            <div className='justify-content-center mb-6'>
                <SecondaryBorderLinkButton href={getRedirect()} target='_blank' rel='noreferrer'>Get Started</SecondaryBorderLinkButton>
            </div>
            <ul style={{ listStyle: 'none' }}>
                {included.map(item => {
                    return <li key={item.feature} className='mb-3 flex font-bold'>
                        <div className='mr-4'>
                            <SuccessIcon />
                        </div>
                        <p>{item.feature}</p>
                    </li>;
                })}
            </ul>
            {excluded &&
                <ul style={{ listStyle: 'none' }} className='mt-5'>
                    {excluded.map(item => {
                        return <li key={item.feature} className='mb-3 flex font-bold'>
                            <div className='mr-4'>
                                <ErrorIcon />
                            </div>
                            <p style={{ color: 'rgba(159, 153, 153, 1)' }}>{item.feature}</p>
                        </li>;
                    })}
                </ul>
            }
            {disclaimer &&
                <p className='font-bold text-xs mt-5 w-12rem'>{disclaimer}</p>
            }
        </div>
    );
}