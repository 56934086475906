import mmgerrrie from '../images/feedbacks/mmgerrrie.png';
import vieraproductive from '../images/feedbacks/vieraproductive.png';
import oliviasstudying from '../images/feedbacks/oliviasstudying.png';
import riavida from '../images/feedbacks/ria.vida_.png';
import reshannastudies from '../images/feedbacks/reshannastudies.png';
import studybubble from '../images/feedbacks/_study_bubble_.png';
import studybubble2 from '../images/feedbacks/_study_bubble2.png';

export const feedbacks = [
    {
        network: 'instagram',
        from: '@klaupaucia',
        text: 'I study now every evening korean with it and I have never made so much progress since I found lofi.co',
        image: null
    },
    {
        network: 'instagram',
        from: '@princesshoneytea',
        text: 'I love the updates! And the new fireplace sound. Super happy with my premium membership. 💖',
        image: null
    },
    {
        network: 'twitter',
        from: '@valsopi',
        text: 'Hot damn! What a... thing 💥',
        image: null
    },
    {
        network: 'tiktok',
        from: '@applezjooces',
        text: 'Omg I used to always listen to Spotify but I hate the ads ima try this out',
        image: null
    },
    {
        network: 'instagram',
        from: '@mmgerrrie',
        text: null,
        image: mmgerrrie
    },
    {
        network: 'instagram',
        from: '@yours_trulee_y',
        text: 'I’m gonna try this website. This is perfect ❤️',
        image: null
    },
    {
        network: 'twitter',
        from: '@MertCanEyriyer',
        text: 'Wanna focus on work? Here is one of the best solutions: lofi.co',
        image: null
    },
    {
        network: 'instagram',
        from: '@likis.art',
        text: 'I use this like everyday! Its amazing and relaxing for people who like having background music while studying!',
        image: null
    },
    {
        network: 'instagram',
        from: '@hope_world344',
        text: 'I tried it and it’s so calming ❤️ ',
        image: null
    },
    {
        network: 'instagram',
        from: '@vieraproductive',
        text: null,
        image: vieraproductive
    },
    {
        network: 'instagram',
        from: '@khalylebdelli',
        text: 'I swear to god ill never regret binging reels after discovering this',
        image: null
    },
    {
        network: 'instagram',
        from: '@tenuyuu',
        text: 'this is so cool omg i never knew a website like this existed, i literally love lofi music so much 😭😭',
        image: null
    },
    {
        network: 'instagram',
        from: '@luxaete',
        text: 'so thankful for lofi.co honestly',
        image: null
    },
    {
        network: 'instagram',
        from: '@oliviasstudying',
        text: null,
        image: oliviasstudying
    },
    {
        network: 'twitter',
        from: '@lakuapik',
        text: 'for those who love lofi music, this is the best place i\'ve ever visited 🙌',
        image: null
    },
    {
        network: 'twitter',
        from: '@karlnaptruer',
        text: 'lofi.co is the only thing keeping me going rn',
        image: null
    },
    {
        network: 'instagram',
        from: '@ria.vida_',
        text: null,
        image: riavida
    },
    {
        network: 'instagram',
        from: '@reshannastudies',
        text: null,
        image: reshannastudies
    },
    {
        network: 'twitter',
        from: '@luvmoth',
        text: 'this website is so comforting ALL students should be aware of this lofi.co',
        image: null
    },
    {
        network: 'twitter',
        from: '@mixedbykelvin',
        text: 'lofi.co is genius 🔥',
        image: null
    },
    {
        network: 'twitter',
        from: '@emrebuyukozkan',
        text: 'if you ever wanted to mix ambience sound apps with chill-hop channels on youtube, lofi.co does it for you',
        image: null
    },
    {
        network: 'twitter',
        from: '@Desalothh',
        text: 'lofi.co existing is the second best thing that\'s ever happened to me',
        image: null
    },{
        network: 'instagram',
        from: '@_study_bubble_',
        text: null,
        image: studybubble
    },
    {
        network: 'instagram',
        from: '@_kawaiigallery_',
        text: 'gonna start using it ASAP!! ✨🤍',
        image: null
    },
    {
        network: 'instagram',
        from: '@letterstojulianna',
        text: 'oh i vibe with this hardcore',
        image: null
    },
    {
        network: 'instagram',
        from: '@nooxkafe',
        text: 'Ahh this is so cute!!! Def gonna try this! Ty for the suggestion ❤❤❤️️️️',
        image: null
    },
    {
        network: 'twitter',
        from: '@MertCanEyriyer',
        text: 'Wanna focus on work? Here is one of the best solutions: lofi.co',
        image: null
    },
    {
        network: 'instagram',
        from: '@ohmy.chewie',
        text: 'OMG WHAT IS THIS WEBSITE IS SO COOL!!!!!!',
        image: null
    },
    {
        network: 'instagram',
        from: '@_study_bubble',
        text: null,
        image: studybubble2
    },
];