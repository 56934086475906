import React from 'react';
import Dropdown from '../../shared/Dropdown';
import { pricingFaq } from '../../../common/faq';

export default function Faq() {

    return (
        <section className='flex flex-column align-items-center mb-8'>
            <h2 className='text-center text-4xl md:text-5xl font-bold mb-8'>
                Frequently Asked Questions
            </h2>
            {pricingFaq.map((faq, index) => {
                return (
                    <Dropdown key={index} faq={faq} />
                );
            })}
        </section>
    );
}