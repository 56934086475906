import React, { useEffect, useRef, useState } from 'react';

export default function GifVideo({ video }) {
    const ref = useRef();
    const [inView, setInView] = useState(ref.current);

    useEffect(() => {
        if (inView) {
            ref.current?.play();
        } else {
            ref.current?.pause();
        }
    }, [ref, inView]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setInView(entry.isIntersecting);
        });
        observer.observe(ref.current);
    }, []);

    return (
        <video width='100%' ref={ref} muted autoPlay playsInline loop>
            <source src={video} type="video/mp4" />
        </video>
    );
}