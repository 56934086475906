import React from 'react';
import TwitterIcon from '../../../assets/twitter-icon.svg';
import InstagramIcon from '../../../assets/instagram-icon.svg';
import TiktokIcon from '../../../assets/tiktok-icon.svg';
import TwitterCircleIcon from '../../../assets/twitter-circle-icon.svg';
import InstagramCircleIcon from '../../../assets/instagram-circle-icon.svg';

export default function FeedbackCard({ theme, feedback, type }) {

    const dynamicCardStyles = theme === 'light' ?
        {
            background: '#FFFFFF',
            color: '#212020'
        } :
        {
            background: '#14141D',
            color: '#FFFFFF'
        };

    const cardStyles = {
        padding: '32px',
        margin: '0 0.5rem',
        borderRadius: '12px',
        width: 'fit-content',
        minWidth: '300px',
        height: 'fit-content',
        boxShadow: '0px 9px 20px rgba(0, 0, 0, 0.1)'
    };

    const getIcon = () => {
        if (type === 'mosaic') {
            if (feedback.network === 'twitter') {
                return <TwitterIcon />;
            } else if (feedback.network === 'instagram') {
                return <InstagramIcon />;
            } else if (feedback.network === 'tiktok') {
                return <TiktokIcon />;
            }
        } else if (type === 'scroll') {
            if (feedback.network === 'twitter') {
                return <TwitterCircleIcon />;
            } else if (feedback.network === 'instagram') {
                return <InstagramCircleIcon />;
            } else if (feedback.network === 'tiktok') {
                return <TiktokIcon />;
            }
        }
    };

    if (type === 'scroll' && !feedback.image)
        return (
            <div style={{ ...cardStyles, ...dynamicCardStyles }}>
                <div className='grid'>
                    <div className='col-2 flex justify-content-center'>
                        {getIcon()}
                    </div>
                    <div className='col-10'>
                        <p className='mb-2 font-bold'>{feedback.from}</p>
                        {feedback.text && <p className='font-helvetica'>{feedback.text}</p>}
                        {feedback.image && <img src={feedback.image} alt={feedback.from} />}
                    </div>
                </div>
            </div>
        );

    if (type === 'mosaic')
        return (
            <div style={{ ...cardStyles, ...dynamicCardStyles, margin: '8px', width: '270px', height: '100%' }}>
                <div className='flex flex-column'>
                    <div className='flex align-items-center mb-2'>
                        {getIcon()}
                        <p className='ml-2 font-bold'>{feedback.from}</p>
                    </div>
                    {feedback.text && <p>{feedback.text}</p>}
                    {feedback.image && <img src={feedback.image} alt={feedback.from} />}
                </div>
            </div>
        );

    else return null;
}