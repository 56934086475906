import React from 'react';

export default function Hero() {
    return (
        <div className='text-center mb-4'>
            <h2 className='text-center text-4xl md:text-5xl font-bold mb-4'>
                <span style={{ display: 'block' }}>Get More Done</span>
                <span style={{ display: 'block' }}>With Premium</span>
            </h2>
        </div>
    );
}