import React from 'react';
import { Animation, MosaicContainer } from './styled';
import MosaicItems from '../MosaicItems';

export default function Mosaic() {
    return (
        <section className='mb-8 w-full'>
            <h2 className='text-center text-4xl md:text-5xl font-bold mb-8'>
                <span style={{ display: 'block' }}>Join the <span className='text-primary'>lofi.co</span></span>
                <span style={{ display: 'block' }}>community</span>
            </h2>
            <Animation className='-mx-2 md:-mx-4'>
                <MosaicContainer>
                    <MosaicItems />
                    <MosaicItems />
                </MosaicContainer>
            </Animation>
        </section>
    );
}